.ReactTable .rt-tr-group {
  max-height: 40px;
}
.ReactTable .rt-thead.-header {
  box-shadow: none;
  background-color: rgba(92, 106, 196, 0.12);
}
.ReactTable select {
  height: 29px;
  padding: 5px 7px;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #3f4eae;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #3f4eae;
}
.Polaris-Card__Section h3 {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  text-transform: uppercase;
  margin: 0;
}
.summary li {
  padding: 5px 0;
}
.Polaris-Card__Section ul li i {
  color: #3f4eae;
}
h3.h3 {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
}
label {
  padding-right: 10px;
}
input[type="text"] {
  font-size: 1.3rem;
  text-align: center;
}
.mb {
  padding-bottom: 20px;
}
