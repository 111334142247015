.DateInput_input__focused {
  border-bottom: 2px solid #3f4eae;
  cursor: pointer;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #3f4eae;
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 16px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 55px;
  z-index: 2;
  text-align: left;
}
.DateInput_input {
  font-weight: 400;
  font-size: 16px;
}
