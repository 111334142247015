.Polaris-Navigation {
  min-width: 160px !important;
  width: 160px !important;
}
.navigation-item a {
  text-decoration: none;
  color: #212b36;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  margin: 0 0.8rem;
  border-radius: 3px;
}

.navigation-item.mobile {
  font-size: 1.75rem;
  margin: 0.5rem 0;
}

.navigation-item.mobile a {
  color: #3b0062;
}

.navigation-item a:active,
.navigation-item a.active {
  background-color: rgba(92, 106, 196, 0.12);
  font-weight: 600;
  color: rgb(32, 46, 120);
}
.navigation-item a:hover {
  background-color: rgba(223, 227, 232, 0.3);
  color: rgb(32, 46, 120);
}
.navigation-item a:active .Polaris-Navigation__Icon svg,
.navigation-item a.active .Polaris-Navigation__Icon svg,
.navigation-item a:hover .Polaris-Navigation__Icon svg {
  fill: #5c6ac4;
}

.navigation-item:last-of-type {
  padding-right: 0;
}

.navigation-item button {
  font: inherit;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
}

.navigation-item button:hover,
.navigation-item button:active {
  color: #fab83f;
}
