.ReactTable .rt-thead.-filter {
  max-height: 34px;
}
.ReactTable .rt-tr input {
  max-height: 30px;
}
.ReactTable .rt-thead.-header {
  box-shadow: none;
  background-color: rgba(92, 106, 196, 0.12);
}
.ReactTable select {
  height: 29px;
  padding: 5px 7px;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #3f4eae;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #3f4eae;
}
a.download-button {
  text-decoration: none;
}
a.download-button .Polaris-Button.Polaris-Button--sizeSlim {
  margin-bottom: 10px;
}
.Polaris-Button.Polaris-Button--destructive.Polaris-Button--sizeSlim {
  min-height: 2rem;
  padding: 0.3rem 0.6rem;
  font-size: 10px;
}
.Polaris-Button.Polaris-Button--destructive.Polaris-Button--sizeSlim
  span.Polaris-Button__Content {
  font-size: 1.2rem;
}
