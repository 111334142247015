.ReactTable .rt-tr-group {
  max-height: 40px;
}
.ReactTable .rt-thead.-header {
  box-shadow: none;
  background-color: rgba(92, 106, 196, 0.12);
}
.ReactTable select {
  height: 29px;
  padding: 5px 7px;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #3f4eae;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #3f4eae;
}
.Polaris-ButtonGroup {
  justify-content: flex-end;
}
.Polaris-ButtonGroup button {
  min-height: 3rem;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row-reverse;
}
.header-dates {
  flex: 0 0 40%;
}
.header-dates p {
  flex: 0 0 0%;
  margin-bottom: 5px;
}
.Polaris-Button {
  min-width: 130px !important;
}
.Polaris-Spinner--sizeSmall {
  height: 1.3rem !important;
  width: 1.3rem !important;
}
