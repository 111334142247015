.auth-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 40%;
  transform: translateY(-50%);
}
.auth-container > .card-container {
  position: relative;
  z-index: 1;
}
.auth-container .Polaris-Card {
  width: 48rem;
}
input {
  height: 38px;
}
