.App {
  min-height: 100vh;
}

.App-topbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid #dfe3e8;
  z-index: 512;
}
.App-topbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100vw;
  box-sizing: border-box;
  height: 5.6rem;
  background-color: #000;
}
.App-topbar-brand {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 24rem;
  min-width: 24rem;
  background-color: #000;
  padding-left: 1.6rem;
  padding-right: 0.8rem;
}
.App-topbar-brand a {
  display: flex;
  height: 100%;
  align-items: center;
  text-decoration: none;
}
.App-topbar-brand a h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  padding-left: 2rem;
}
.App-topbar-profile {
  width: 12rem;
}
.App-sidebar-container {
  position: absolute;
  top: 5.6rem;
  height: calc(100% - 5.6rem);
}
.App-topbar-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 2rem;
  font-size: 1.5rem;
}
.App-main {
  padding-left: 160px;
  padding-top: 5.6rem;
  min-height: 100vh;
}
